import { render, staticRenderFns } from "./HomeFeatures.vue?vue&type=template&id=db387508&lang=pug&"
import script from "./HomeFeatures.vue?vue&type=script&lang=js&"
export * from "./HomeFeatures.vue?vue&type=script&lang=js&"
import style0 from "./HomeFeatures.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!/opt/build/repo/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib'
import { VContainer } from 'vuetify/lib'
import { VFlex } from 'vuetify/lib'
import { VLayout } from 'vuetify/lib'
import { VTab } from 'vuetify/lib'
import { VTabItem } from 'vuetify/lib'
import { VTabs } from 'vuetify/lib'
import { VTabsItems } from 'vuetify/lib'
installComponents(component, {VChip,VContainer,VFlex,VLayout,VTab,VTabItem,VTabs,VTabsItems})
